import React from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import "../css/wp-2020.css";
import SEO from "../components/seo";
import BackgroundImage from "gatsby-background-image";
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image";
import PlaceholderFeaturedImage from "../components/PlaceholderFeaturedImage";
import TagList from "../components/TagList";
import CatList from "../components/CatList";
import BackToBlogLink from "../components/BackToBlogButton";
import BlogPageBannerWrapper from "../components/BlogPageBannerWrapper"

const BlogCats = ({ catList }) => {
  const listOfCats = catList;
  const cats = [];

  listOfCats.map((node) => cats.push(node.name + " "));
  return cats;
};

export default function BlogPost({ data }) {
  const post = data.allWpPost.edges[0].node;
  const SEOTitle = post.title + "- Executive Coach Blog";
  const imageData = data.banner.childImageSharp.fluid;
  const author = post.author.node.firstName + " " + post.author.node.lastName;
  const featuredImage = post.featuredImage;
  const gatsbyImage =
    featuredImage === undefined || featuredImage === null
      ? null
      : getImage(featuredImage.node.localFile);

  const alt =
    featuredImage === undefined ||
    featuredImage === null ||
    featuredImage.node.altText === undefined ||
    featuredImage.node.altText === null
      ? String("Angela Cooney Blog post")
      : String(featuredImage.node.altText);
  return (
    <Layout
      headerClass="bg-white relative"
      bodyClass="px-0 md:px-0 lg:px-0 min-h-screen"
    >
      <SEO title="Blog" />
      <BlogPageBannerWrapper image={imageData} >

      
        <div className="container mx-auto px-6 mt-6 md:px-10 lg:px-16 pb-4 bg-purple-900 heropattern-texture-teal-900">
          <BackToBlogLink />
          <article
            className="py-24 mx-auto max-w-7xl w-full xl:w-4/6"
            itemid="#"
            itemscope
            itemtype="http://schema.org/BlogPosting"
          >
            <div className="w-full mx-auto mb-12 text-left">
              {gatsbyImage ? (
                <GatsbyImage
                  image={gatsbyImage}
                  className="object-cover w-full h-auto col-span-1 bg-center "
                  alt={alt}
                  loading="lazy"
                />
              ) : (
                <PlaceholderFeaturedImage alt={alt} />
              )}
              <div className="flex flex-row flex-wrap mt-6 mb-2 text-xs font-semibold tracking-wider uppercase text-pink-600">
                <CatList catNodes={post.categories.nodes} />
              </div>
              <h1
                className="mb-3 text-3xl font-bold leading-tight text-teal-800 hover:text-teal-400 md:text-4xl"
                itemprop="headline"
                title={post.title}
              >
                {post.title}
              </h1>
              <div className="flex flex-row flex-wrap mb-6 space-x-2">
                <TagList tagNodes={post.tags.nodes} />
              </div>
              <a className="flex items-center text-gray-700" href="#">
                <div className="avatar">
                  <StaticImage
                    src="../images/head-shot.png"
                    alt="Angela Cooney - EMCC certified Executive Coach - Senior Practitioner - Dublin "
                  />
                </div>
                <div className="ml-2">
                  <p className="text-sm font-semibold text-pink-500">
                    {post.author.node.firstName}&nbsp;
                    {post.author.node.lastName}
                  </p>
                  <p className="text-sm text-gray-500">{post.date}</p>
                </div>
              </a>
            </div>

            <div className="w-full mx-auto prose text-white entry-content" dangerouslySetInnerHTML={{ __html: post.content }} />
          </article>
          <BackToBlogLink />
        </div>
        </BlogPageBannerWrapper>
    </Layout>
  );
}
export const query = graphql`
  query ($slug: String!) {
    allWpPost(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          title
          content
          date(formatString: "DD MMMM YYYY")
          author {
            node {
              firstName
              lastName
            }
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 360
                    placeholder: BLURRED
                    formats: [AVIF, WEBP, JPG]
                  )
                }
              }
              altText
            }
          }
          categories {
            nodes {
              name
              slug
            }
          }
          tags {
            nodes {
              name
              slug
            }
          }
        }
      }
    }
    banner: file(
      relativePath: { eq: "blog-executive-coach-angela-cooney-banner.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
