import React from "react";

import BackgroundImage from "gatsby-background-image";

const BlogPageBannerWrapper = ({ image, children }) => {
  return (
    <div className="min-h-screen h-full flex flex-col">
      <BackgroundImage
        Tag="div"
        className="w-full h-40 sm:h-32 lg:h-56"
        fluid={image}
      >
        <div className="container mx-auto mt-6 px-4 sm:px-2 md:px-20 lg:px-24 mb-24">
          <div className="w-7/12 mt-10 sm:mt-12 lg:mt-20 font-heading self-start">
            <h1 className="text-4xl md:text-3xl lg:text-6xl text-pink-600 leading-tight text-shadow-xl">
              My Executive Coach blog
            </h1>
          </div>
          
        </div>
      </BackgroundImage>
      {children}
    </div>
  );
};
export default BlogPageBannerWrapper;
