import React from "react";
import cx from "classnames";

const StyledButton = ({ className, children, ...props }) => {
  className = cx(
    "py-2 px-4  text-base text-white font-base uppercase rounded shadow-md hover:-translate-1",
    className
  );
  return (
    <button className={className} {...props}>
      {children}
    </button>
  );
};

const PinkButton = ({ className, children, ...props }) => {
    className = cx("bg-pink-600 hover:bg-pink-500", className)
    return (
        <StyledButton className={className} {...props} >
        {children}
        </StyledButton>
    )
}


export default StyledButton

export {PinkButton}