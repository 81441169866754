import React from "react"

import Link from "gatsby-link"

const TagList = ({ tagNodes }) => {
  return (
    <>
      {tagNodes.map((tagNode) => (
         
        <Link to={"/tags/" + tagNode.slug}
          className="text-teal-800 hover:text-teal-400 bg-white border-pink-600 border-2 rounded-4xl px-3 py-2 text-sm font-semibold underline"
        >
          {tagNode.name}
        </Link>
      ))}
    </>
  );
};
export default TagList;
