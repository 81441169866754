import React from "react";

import Link from "gatsby-link";

import StyledButton from "./StyledButton";

const BackToBlogLink = () => {
  return (
    <div className="flex flex-row w-full mt-5">
      <div className="row-start-1">
        <Link to="/blog">
          <StyledButton className="bg-teal-900 hover:bg-teal-500 ">
            &lt; &lt; Back to blog
          </StyledButton>
        </Link>
      </div>
    </div>
  );
};

export default BackToBlogLink;
