import React from "react";

import { StaticImage } from "gatsby-plugin-image";

const PlaceholderFeaturedImage = ({ alt }) => {
  return (
    <StaticImage
      src="../images/blog-post-placeholder.svg"
      alt={alt}
      placeholder="blurred"
      className="object-cover w-full h-auto col-span-1 bg-center "
      loading="lazy"
    />
  );
};

export default PlaceholderFeaturedImage;
