import React from "react";

import Link from "gatsby-link";

const CatList = ({ catNodes }) => {
  return (
    <div className="flex flex-row flex-wrap">
      {catNodes.map((catNode) => (
        <div className="px-1">
          <Link
            to={"/categories/" + catNode.slug}
            className="underline text-lg font-bold leading-loose"
          >
            {" "}
            {catNode.name}{" "}
          </Link>
        </div>
      ))}
    </div>
  );
};

export default CatList;
